<template>
  <div id="app">
    <h1>Bem-vindo ao RedHat (RHAT) Project!</h1>
    <button @click="getTokenInfo">Teste  OK3 </button>
    <p>{{ tokenInfo }}</p>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      tokenInfo: ''
    }
  },
  methods: {
    async getTokenInfo() {
      try {
        const response = await fetch('http://backend:5000/api/token-info');
        const data = await response.json();
        this.tokenInfo = data.message;
      } catch (error) {
        console.error(error);
      }
    }
  }
}
</script>

<style>
#app {
  text-align: center;
}
</style>
